import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"

const FeaturePage = () => (
  <Layout>
    <Seo title="Features" />
    <p>
      <Link to="/">Home</Link>
    </p>
    <h2>New features</h2>
    Shooters Diary 2 (SD2)
    
  </Layout>
)

export default FeaturePage
